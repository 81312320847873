<ion-header>
	<ion-toolbar color="primary">
		<ion-title>OPTIONS</ion-title>
		<ion-buttons slot="start">
			<ion-button (click)="closeModal(false)"
				><ion-icon slot="icon-only" name="arrow-back" aria-label="Back"></ion-icon
			></ion-button>
		</ion-buttons>
		<ion-buttons slot="end">
			<ion-button (click)="closeModal(false)"
				><ion-icon slot="icon-only" name="close-circle" aria-label="Close"></ion-icon
			></ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-card>
		<ion-grid>
			<ion-row>
				<ion-col size="12">
					<h1 class="ion-text-wrap global-name ion-no-margin">{{ currentSelection.store?.name }}</h1>
					<p class="global-details ion-no-margin" *ngIf="!!currentSelection.store?.address">
						{{ currentSelection.store?.address }}
					</p>
					<p class="global-details ion-no-margin" *ngIf="!!currentSelection.store.status?.distance">
						{{ currentSelection.store.status?.distance | distance }} Away
					</p>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col size="7" class="ion-text-left">
					<ion-grid class="ion-no-padding">
						<ion-row>
							<ion-col>
								<ion-badge class="badge-success" *ngIf="currentSelection.store.status.isOpen">OPEN</ion-badge>
								<ion-badge class="badge-warning" *ngIf="!currentSelection.store.status.isOpen">CLOSED</ion-badge>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col class="ion-text-left">
								<p class="global-details ion-no-margin" *ngIf="!currentSelection.store.status.isOpen">
									Opens at {{ currentSelection.store.tradingDetails.times.open }}
								</p>
								<p class="global-details ion-no-margin" *ngIf="currentSelection.store.status.isOpen">
									Closes at {{ currentSelection.store.tradingDetails.times.close }}
								</p>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-col>
				<ion-col size="5" class="ion-float-right ion-text-right">
					<ion-button
						*ngIf="!tableNo"
						color="secondary"
						fill="outline"
						(click)="selectStore()"
						aria-label="Change store"
						>Change</ion-button
					>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card>
	<ion-card *ngIf="orderingEnabled">
		<ion-list class="card-list">
			<ion-list-header>
				<ion-label class="global-name">Order Method</ion-label>
			</ion-list-header>
			<ion-radio-group (ionChange)="selectionChange($event)" #destinationType>
				<ion-item disabled="{{ !currentSelection.store }}" *ngIf="isMethodAvailable('delivery')">
					<ion-icon name="bicycle-outline" slot="start"></ion-icon>
					<ion-label>{{ getOrderMethodName('delivery') || 'Delivery' }}</ion-label>
					<ion-radio
						slot="end"
						color="secondary"
						value="destinationType.delivery"
						[class.radio-checked]="currentSelectionFormState.destinationType.delivery"
					></ion-radio>
				</ion-item>
				<ion-item disabled="{{ !currentSelection.store }}" *ngIf="isMethodAvailable('collection')">
					<ion-icon name="basket-outline" slot="start"></ion-icon>
					<ion-label>{{ getOrderMethodName('collection') || 'Collection' }}</ion-label>
					<ion-radio
						slot="end"
						color="secondary"
						value="destinationType.collect"
						[class.radio-checked]="currentSelectionFormState.destinationType.collect"
					></ion-radio>
				</ion-item>
				<ion-item disabled="{{ !currentSelection.store }}" *ngIf="isMethodAvailable('curbside')">
					<ion-icon name="car-outline" slot="start"></ion-icon>
					<ion-label>{{ getOrderMethodName('curbside') || 'Curbside' }}</ion-label>
					<ion-radio
						slot="end"
						color="secondary"
						value="destinationType.curbside"
						[class.radio-checked]="currentSelectionFormState.destinationType.curbside"
					></ion-radio>
				</ion-item>
				<ion-item disabled="{{ !currentSelection.store }}" *ngIf="isMethodAvailable('drivethru')">
					<ion-icon name="car-sport-outline" slot="start"></ion-icon>
					<ion-label>{{ getOrderMethodName('drivethru') || 'Drive Thru' }}</ion-label>
					<ion-radio
						slot="end"
						color="secondary"
						value="destinationType.drivethru"
						[class.radio-checked]="currentSelectionFormState.destinationType.drivethru"
					></ion-radio>
				</ion-item>
			</ion-radio-group>
		</ion-list>
	</ion-card>
	<ion-card *ngIf="orderingEnabled">
		<ion-list class="card-list">
			<ion-list-header>
				<ion-label class="global-name">Order Time</ion-label>
			</ion-list-header>
			<ion-radio-group>
				<ion-item (click)="asap()">
					<ion-icon name="timer-outline" slot="start"></ion-icon>
					<ion-label
						>ASAP <span class="sub-text"><br />Place an order and get it as soon as its prepared</span></ion-label
					>
					<ion-radio
						color="secondary"
						slot="end"
						value="when.asap"
						[class.radio-checked]="currentSelectionFormState.when.asap"
					></ion-radio>
				</ion-item>
				<ion-item (click)="schedule()">
					<ion-icon name="calendar-outline" slot="start"></ion-icon>
					<ion-label
						><h2>
							Schedule for later<span class="sub-text"><br />Place an order for a later date or time</span>
						</h2>
						<p *ngIf="this.scheduleDateTimePretty !== ''">{{ this.scheduleDateTimePretty }}</p></ion-label
					>
					<ion-radio
						disabled="{{ !currentSelection.store }}"
						color="secondary"
						slot="end"
						value="when.schedule"
						[class.radio-checked]="currentSelectionFormState.when.schedule"
					></ion-radio>
				</ion-item>
			</ion-radio-group>
		</ion-list>
	</ion-card>
	<ion-card>
		<ion-list class="card-list">
			<ion-list-header>
				<ion-label class="global-name">Address</ion-label>
				<ion-button fill="outline" color="secondary" class="section" (click)="address()" aria-label="Add new address"
					>Add New</ion-button
				>
			</ion-list-header>
			<ion-radio-group (ionChange)="selectionChange($event)" #dest>
				<ion-item *ngFor="let l of locations || []">
					<ion-icon class="address-icon" name="location-outline" slot="start"></ion-icon>
					<ion-label class="ion-text-wrap locationName"
						>{{ l.nickName }} <span class="sub-text"><br />{{ l.address }}</span></ion-label
					>
					<span [ngSwitch]="(l.meta && l.meta.type) || 'UNKNOWN'">
						<ion-badge slot="end" *ngSwitchCase="'PRECISE'" class="geotype badge-success">ADDRESS</ion-badge>
						<ion-badge slot="end" *ngSwitchCase="'APPROXIMATE'" class="geotype badge-tertiary">AREA</ion-badge>
						<ion-badge slot="end" *ngSwitchCase="'UNKNOWN'" class="geotype badge-warning">UNKNOWN</ion-badge>
					</span>
					<ion-radio
						slot="end"
						color="secondary"
						value="selectedLocationName_{{ l.nickName }}"
						[class.radio-checked]="isLocationSelected(l.nickName)"
					></ion-radio>
				</ion-item>
			</ion-radio-group>
		</ion-list>
	</ion-card>
	<ion-toolbar class="global-footer" color="primary">
		<ion-grid class="ion-no-padding">
			<ion-row>
				<ion-col size="12" (click)="closeModal(true)">
					<ion-button class="footer-button" expand="full" fill="clear" aria-label="Accept">ACCEPT</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-toolbar>
</ion-content>
